<template>
  <div>
    <b-row>
      <b-col md="2" cols="6" v-for="brand in brands" :key="brand._id">
        <b-link :to="`/brand/${brand.slug}`">
          <b-card>
            <div class="text-center">
              <div class="brand-logo">
                <img
                  class="w-100"
                  :src="brand.logo.image"
                  :alt="brand.logo.alt"
                />
              </div>
              {{ brand.name }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import { BRow, BCol, BCard, BLink } from "bootstrap-vue";

export default {
  data() {
    return {
      models: [],
      brand: null,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
  },
  methods: {
    getModels() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/model`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.models = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
  mounted() {
    this.getModels();
  },
};
</script>

<style>
.brand-logo {
  max-width: 80px;
  margin: 0 auto;
}
</style>
